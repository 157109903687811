import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'project';
  constructor(private meta: Meta) { }

  ngOnInit(): void {
    // Add a meta tag for description
    this.meta.addTag({ name: 'Gold Bharat', content: 'Goldsikka Limited is part of a diversified three decades old Aztar Group. Est. in 1990, which specializes in Print Media, Tech Application, Insurance Broking, Data Analytics, Ecommerce, Non Tangible Asset Management, Property Underwriting, Corporate Law Advisory and Activations' });

    // Add a meta tag for keywords
    this.meta.addTag({ name: 'Gold Bharat', content: 'Gold Product' });
  }
}
