import { Component } from '@angular/core';

@Component({
  selector: 'app-footerpage',
  templateUrl: './footerpage.component.html',
  styleUrls: ['./footerpage.component.scss']
})
export class FooterpageComponent {

}
