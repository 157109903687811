<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="card-title m-b-0">Orders History</h5>
        </div>
        <div class="buy">
          <label for="">Select Order Type</label><br>
          <select class="formselect-gold-value" name="" id="" (change)="updateBuy($event)">
            <option class="optionTake" value="BUY">BUY</option>
            <option class="optionTake" value="BOOK">BOOK</option>

          </select>
        </div>
        <div *ngIf="noData" class="noData">
          <img src="../../assets/Animation - 1712133187330.gif" alt="">
          <h3 class="text-danger text-center">No Data Found</h3>
        </div>
        <div class="table-responsive" *ngIf="!noData">
          <div class="table-container">
            <table class="table text-center">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Product</th>
                  <th scope="col">Quantity (Grams)</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total Price</th>
                  <th scope="col" *ngIf="!isTrade">Penalty</th>
                  <th scope="col" *ngIf="!isTrade">Payment Status</th>
                  <th scope="col" *ngIf="!isTrade">Order Status</th>

                  <th scope="col" *ngIf="!isTrade">Action</th>
                </tr>
              </thead>
              <tbody class="customtable">
                <tr *ngFor="let pro of orders">
                  <td>{{ pro.id }}</td>
                  <td>{{ pro.created_at | date: 'medium' }}</td>
                  <td [ngClass]="pro.product_name == 'Gold' ? 'myGoldColor' : 'mySilverColor'">{{ pro.product_name }}
                  </td>
                  <td>{{ pro.qty_purchased }}</td>
                  <td>
                    <ng-container *ngIf="pro.product_name === 'Gold'; else otherProduct">
                      <ng-container *ngIf="!pro.trade; else tradeGold">
                        {{ pro.purchased_price | currency:"₹" }}/Grams
                      </ng-container>
                      <ng-template #tradeGold>
                        {{ pro.book_rate | currency:"₹" }}/Grams
                      </ng-template>
                    </ng-container>
                    <ng-template #otherProduct>
                      <ng-container *ngIf="!pro.trade; else tradeOther">
                        {{ pro.purchased_price| currency:"₹"}}/Kg
                      </ng-container>
                      <ng-template #tradeOther>
                        {{ pro.book_rate | currency:"₹" }}/Kg
                      </ng-template>
                    </ng-template>
                  </td>
                  <td>{{ pro.total_price | currency:"₹" }}</td>
                  <td *ngIf="!isTrade">{{ pro.penalty | numberFormat }}</td>

                  <td *ngIf="!isTrade">
                    <ng-container [ngSwitch]="pro.payment_status">
                      <p *ngSwitchCase="0" class="ppending">Payment Pending</p>
                      <p *ngSwitchCase="1" class="psubmited">Document Submitted</p>
                      <p *ngSwitchCase="2" class="pconform">Payment Confirmed</p>
                      <p *ngSwitchCase="3" class="prejected">Payment Rejected</p>
                    </ng-container>
                  </td>
                  <td *ngIf="!isTrade">
                    <ng-container [ngSwitch]="pro.order_status">
                      <p *ngSwitchCase="0" class="ppending">Order Created</p>
                      <p *ngSwitchCase="1" class="pconform">Order Accepted</p>
                      <p *ngSwitchCase="2" class="prejected">Order Rejected</p>
                      <p *ngSwitchCase="3" class="psubmited" data-bs-toggle="modal" data-bs-target="#conformDelivery"
                        (click)="getOrderId(pro.id)">Confirm Delivery</p>
                      <p *ngSwitchCase="4" class="pconform">Order Delivered</p>
                      <p *ngSwitchCase="5" class="pconform">Picker Selected</p>
                    </ng-container>
                  </td>
                  <ng-container *ngIf="!isTrade">
                    <td *ngIf="pro.payment_status != 0">
                      <button class="btn" disabled>Document Updated</button>
                    </td>
                    <td *ngIf="pro.payment_status == 0">
                      <button class="btn" (click)="onGetId(pro.id)" data-bs-toggle="modal"
                        data-bs-target="#exampleModalGold">Update Payment</button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="table-responsive" *ngIf="!noData">
          <div class="table-container">
            <table class="table1">

              <tbody class="customtable1" *ngFor="let pro of orders">
                <tr>
                  <td>Order Id</td>
                  <td>:</td>
                  <td>{{ pro.id }}</td>

                </tr>
                <tr>
                  <td>Date & Time</td>
                  <td>:</td>
                  <td>{{ pro.created_at | date: 'medium' }}</td>

                </tr>
                <tr>
                  <td>Product</td>
                  <td>:</td>

                  <td [ngClass]="pro.product_name == 'Gold' ? 'myGoldColor' : 'mySilverColor'">{{ pro.product_name }}
                  </td>

                </tr>
                <tr>
                  <td>Quantity (Grams)</td>
                  <td>:</td>
                  <td>{{ pro.qty_purchased }}</td>

                </tr>
                <tr>
                  <td>Price</td>
                  <td>:</td>
                  <td>
                    <ng-container *ngIf="pro.product_name === 'Gold'; else otherProduct">
                      <ng-container *ngIf="!pro.trade; else tradeGold">
                        {{ pro.purchased_price | currency:"₹" }}/Grams
                      </ng-container>
                      <ng-template #tradeGold>
                        {{ pro.book_rate | currency:"₹" }}/Grams
                      </ng-template>
                    </ng-container>
                    <ng-template #otherProduct>
                      <ng-container *ngIf="!pro.trade; else tradeOther">
                        {{ pro.purchased_price | currency:"₹" }}/Kg
                      </ng-container>
                      <ng-template #tradeOther>
                        {{ pro.book_rate | currency:"₹" }}/Kg
                      </ng-template>
                    </ng-template>
                  </td>

                </tr>

                <tr>
                  <td>Total Price</td>
                  <td>:</td>
                  <td>{{ pro.total_price | currency:"₹" }}</td>
                </tr>
                <tr>
                  <td *ngIf="!isTrade">Penalty</td>
                  <td *ngIf="!isTrade">:</td>
                  <td *ngIf="!isTrade">{{ pro.penalty | currency:"₹" }}</td>
                </tr>
                <tr>
                  <td *ngIf="!isTrade">Payment Status</td>
                  <td *ngIf="!isTrade">:</td>
                  <td *ngIf="!isTrade">
                    <ng-container [ngSwitch]="pro.payment_status">
                      <p *ngSwitchCase="0" class="ppending">Payment Pending</p>
                      <p *ngSwitchCase="1" class="psubmited">Document Submitted</p>
                      <p *ngSwitchCase="2" class="pconform">Payment Confirmed</p>
                      <p *ngSwitchCase="3" class="prejected">Payment Rejected</p>
                    </ng-container>
                  </td>
                </tr>

                <tr>
                  <td *ngIf="!isTrade">Order Status</td>
                  <td *ngIf="!isTrade">:</td>
                  <td *ngIf="!isTrade">
                    <ng-container [ngSwitch]="pro.order_status">
                      <p *ngSwitchCase="0" class="ppending">Order Created</p>
                      <p *ngSwitchCase="1" class="pconform">Order Accepted</p>
                      <p *ngSwitchCase="2" class="prejected">Order Rejected</p>
                      <p *ngSwitchCase="3" class="psubmited" data-bs-toggle="modal" data-bs-target="#conformDelivery"
                        (click)="getOrderId(pro.id)">Confirm Delivery</p>
                      <p *ngSwitchCase="4" class="pconform">Order Delivered</p>
                      <p *ngSwitchCase="5" class="pconform">Picker Selected</p>
                    </ng-container>
                  </td>

                </tr>
                <tr>
                  <td *ngIf="!isTrade">Action</td>
                  <td *ngIf="!isTrade">:</td>
                  <ng-container *ngIf="!isTrade">
                    <td *ngIf="pro.payment_status != 0">
                      <button class="btn" disabled>Payment Updated</button>
                    </td>
                    <td *ngIf="pro.payment_status == 0">
                      <button class="btn" (click)="onGetId(pro.id)" data-bs-toggle="modal"
                        data-bs-target="#exampleModalGold">Update Payment</button>
                    </td>
                  </ng-container>

                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal fade" id="exampleModalGold" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content checkColor">
      <div class="modal-header">
        <h4 class="modal-title myheading-models" id="exampleModalLabel">Upload transaction details</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetOtherForm()"></button>
      </div>
      <div class="modal-body">
        <form action="" [formGroup]="UpdateDocForm" (ngSubmit)="onSubmit()">
          <div class="pop-up-gold">
          </div>
          <div>
            <label for="" class="myheading-models">Upload Receipt</label>
          </div>
          <input class="formselect-gold" (change)="onFileSelectedReceipt($event)" type="file"
            formControlName="transaction_image">
          <!-- (change)="calcAmountForTotal($event)" -->

          <div class="validationError" *ngIf="
               UpdateDocForm
                 .get('transaction_image')
                 ?.hasError('required') &&
               UpdateDocForm.get('transaction_image')?.touched
             ">
            Receipt is required.
          </div>

          <!-- <p *ngIf="isTotal">Current Live Rate Total 
             Amount : <b class="bts">{{fAmount}}</b></p> -->
          <div>
            <label for="" class="myheading-models">Transaction id</label>
          </div>
          <input type="text" formControlName="transation_id" name="" id="" class="formselect-gold"
            placeholder="Enter transaction id" />
          <div class="validationError" *ngIf="
               UpdateDocForm.get('transation_id')?.hasError('required') &&
               UpdateDocForm.get('transation_id')?.touched
             ">
            Transation id is required.
          </div>
          <div class="validationError"
            *ngIf="UpdateDocForm.get('transation_id')?.hasError('pattern') && UpdateDocForm.get('transation_id')?.touched">
            Enter a valid transaction id.
          </div>

          <!-- <div class="validationError" *ngIf="
               UpdateDocForm.get('transation_id')?.hasError('pattern') &&
               UpdateDocForm.get('transation_id')?.touched
             ">
             Please enter a valid amount.
           </div> -->
          <button class="buy-gold-popup" *ngIf="UpdateDocForm.invalid" [disabled]="UpdateDocForm.invalid">
            Upload
          </button>
          <button class="buy-gold-popup-valid" data-bs-dismiss="modal" *ngIf="UpdateDocForm.valid" type="submit">
            Upload
          </button>
        </form>
      </div>
      <!-- <div class="modal-footer">
         <button
           type="button"
           class="btn btn-secondary"
           data-bs-dismiss="modal"
         >
           Close
         </button>
         <button type="button" class="btn btn-primary">
           Save changes
         </button>
       </div> -->
    </div>
  </div>
</div>


<!-- otpConform -->
<div class="modal fade" id="conformDelivery" tabindex="-1" aria-labelledby="exampleModalLabelConform"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content checkColor">
      <div class="modal-header">
        <h3 class="modal-title myheading-models" id="exampleModalLabel">Order Pickup</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="resetOtherForm()"></button>
      </div>
      <div class="modal-body">

        <section class="mainclass">
          <div class="container-fluid">
            <div class="row">
              <div class="loginmaindiv">
                <div class="admin_login_title">
                  <p class="verification-code">Message: {{deliveryDetails?.message}}</p>
                  <p class="verification-code">Date & Time : {{deliveryDetails?.time|date: 'medium'}}</p><br>
                </div>
                <label for="">Order Picked Up By:</label>
                <select name="" id="" class="input-filed" (change)="orderpicker($event)">
                  <option value="self">Self</option>
                  <option value="other">Other</option>

                </select>
                <button class="login-btn" *ngIf="isSelf" (click)="selfConform()">Submit</button>

                <form [formGroup]="otherform" *ngIf="!isSelf" (ngSubmit)="onOtherFormSubmit()">
                  <div class="login-input-div">
                    <label for="">Name of person</label>
                    <input placeholder=" Enter Your Name" type="text" class="input-filed"
                      formControlName="other_person_name" />
                  </div>
                  <div class="validationError" *ngIf="
                    otherform.get('other_person_name')?.hasError('required') &&
                    otherform.get('other_person_name')?.touched
                  ">
                    Name is required.
                  </div>
                  <div class="validationError" *ngIf="
                  otherform.get('other_person_name')?.hasError('pattern') &&
                  otherform.get('other_person_name')?.touched
                ">
                    Please enter a valid name.
                  </div>
                  <label for="">Mobile Number</label>
                  <input placeholder=" Enter Your Number" type="text" class="input-filed" maxlength="10" minlength="10"
                    formControlName="other_person_number" />
                  <span class="number-icon">
                    <p>+91 |</p>
                  </span>

                  <div class="validationError" *ngIf="
                otherform.get('other_person_number')?.hasError('required') &&
                otherform.get('other_person_number')?.touched
              ">
                    Mobile number is required.
                  </div>
                  <div class="validationError" *ngIf="
                otherform.get('other_person_number')?.hasError('pattern') &&
                otherform.get('other_person_number')?.touched
              ">
                    Please enter a valid mobile number.
                  </div>
                  <div class="login-input-div">
                    <label for="">Id Number</label>
                    <input placeholder="Ex:Aadhar/Pan/License" type="text" class="input-filed"
                      formControlName="other_person_id_card_number" />
                  </div>
                  <div class="validationError" *ngIf="
                otherform.get('other_person_id_card_number')?.hasError('required') &&
                otherform.get('other_person_id_card_number')?.touched
              ">
                    Name is required.
                  </div>
                  <label for="">Upload Id Card</label>
                  <input class="input-filed" (change)="otherPersonFile($event)" type="file">
                  <!-- (change)="calcAmountForTotal($event)" -->
                  <!-- <div class="validationError" *ngIf="
               otherform
                 .get('other_person_doc')
                 ?.hasError('required') &&
               otherform.get('other_person_doc')?.touched
             ">
                    Receipt is required.
                  </div> -->
                  <div class="loginbotn-div">
                    <button class="login-btn" *ngIf="otherform.valid" type="submit">Submit</button>
                    <button class="login-btn-invalid" *ngIf="!otherform.valid" type="submit"
                      [disabled]="!otherform.valid">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<!-- <form [formGroup]="loginForm" (ngSubmit)="onOTPConform()">

  <div class="admin_login_title">
    <p class="verification-code">Message: {{deliveryDetails?.message}}</p>
    <p class="verification-code">Date & Time : {{deliveryDetails?.time}}</p><br>
    <h6 class="verification-code">Please conform your product delivery by sending otp to you register
      mobile number.</h6>
  </div>
  <div class="login-input-div">
    <input placeholder=" Enter Your Number" type="text" class="input-filed" maxlength="10"
      minlength="10" formControlName="phone" />
    <span class="number-icon">
      <p>+91 |</p>
    </span>
  </div>
  <div class="validationError" *ngIf="
    loginForm.get('phone')?.hasError('required') &&
    loginForm.get('phone')?.touched
  ">
    Mobile number is required.
  </div>
  <div class="validationError" *ngIf="
    loginForm.get('phone')?.hasError('pattern') &&
    loginForm.get('phone')?.touched
  ">
    Please enter a valid mobile number.
  </div>
  <div class="loginbotn-div">
    <button class="login-btn" *ngIf="loginForm.valid" type="submit">Send OTP</button>
    <button class="login-btn-invalid" *ngIf="!loginForm.valid" type="submit"
      [disabled]="!loginForm.valid">Send OTP</button>

  </div>

</form> -->