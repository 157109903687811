import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HomeService } from '../services/home.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgToastService } from 'ng-angular-popup';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders: any;
  noData: boolean = false;
  UpdateDocForm!: FormGroup;
  selectedFile: File | null = null;
  orderId!: number;
  isTrade: boolean = false;
  deliveryDetails: any;
  otherform!: FormGroup;
  isSelf: boolean = true;
  selectedFileone: File | null = null;
  urlImage: any;
  constructor(private home: HomeService,private cdr: ChangeDetectorRef, private fb: FormBuilder, private toast: NgToastService, private auth: AuthService, private router: Router, private changeDetector: ChangeDetectorRef) {
    this.UpdateDocForm = this.fb.group({
      transaction_image: ['', [Validators.required]],
      transation_id: ['', [Validators.required, Validators.pattern(/^(?!0+$)\d*$/)]],
      order_id: ['']
    });

    this.otherform = this.fb.group({
      order_id: ['', [Validators.required]],
      other_person_name: ['', [Validators.required]],
      other_person_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      other_person_id_card_number: ['', [Validators.required]],
      other_person_doc: []
    });
  }
  onOtherFormSubmit() {
    this.home.userNewPersonDetailspost(this.otherform.value).subscribe((res: any) => {
      this.orderStatusUpdate(this.orderId);
      this.loadOrders('BUY');
      this.otherform.reset();
      window.location.reload();

      this.toast.success({ detail: 'Success', summary: res.msg, position: 'topCenter' });
    },
      err => {
        this.toast.error({ detail: 'Oops!', summary: err.error.msg, position: 'topCenter' })
      })
  }
  resetOtherForm(){
    window.location.reload();
  }
  otherPersonFile(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('other_person_doc', this.selectedFile);

      this.home.imgUpload(formData).subscribe(
        (res: any) => {
          console.log('API Response:', res);
          this.urlImage=res.other_person_doc;
          this.otherform.get('other_person_doc')?.setValue(res.other_person_doc);

        },
        (err) => {
          console.error('Upload error', err);
        }
      );
    }
  }
  
  
  
  onFileSelectedReceipt(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('transaction_image', this.selectedFile);
      this.home.imgUpload(formData).subscribe((res: any) => {
        console.log('API Response:', res);
        this.UpdateDocForm.get('transaction_image')?.setValue(res.transaction_image);
      });
    }

  }



  onGetId(id: number) {
    this.UpdateDocForm
      .get('order_id')
      ?.setValue(id);
  }
  pickUpMsg(id: number) {
    this.home.deliveryMsg(id).subscribe((res: any) => {
      this.deliveryDetails = res.deliveryDetails;
    })
  }
  onSubmit() {
    this.home.receiptUpdate(this.UpdateDocForm.value).subscribe((res: any) => {
      this.loadOrders('BUY');
      this.UpdateDocForm.reset();
      this.toast.success({ detail: 'Success', summary: res.msg, position: 'topCenter' })
    },
      err => {
        this.toast.error({ detail: 'Ooops!', summary: err.error.msg, position: 'topCenter' })
      })
  }
  selfConform() {
    this.orderStatusUpdate(this.orderId);
    this.toast.success({ detail: 'Success', summary: 'Confirmation to pick up the order yourself.', position: 'topCenter' });
    this.loadOrders('BUY');
  }
  ngOnInit(): void {
    this.loadOrders('BUY');
  }
  updateBuy(event: any
  ) {
    this.loadOrders(event.target.value);
  }
  loadOrders(orderType: string): void {
    if (orderType != 'BUY') {
      this.isTrade = true;
    }
    else {
      this.isTrade = false;
    }
    this.home.ordersGet(orderType).subscribe(
      (res: any) => {
        this.orders = res;
        this.noData = !res || res.length === 0; // Set noData flag if no orders or response is empty
      },
      (error) => {
      }
    );
  }
  orderpicker(event: any) {
    console.log(event.target.value)
    if (event.target.value === 'self') {
      this.isSelf = true;
    }
    else {
      this.isSelf = false;
    }
  }
  getOrderId(id: number) {
    this.orderId = id;
    this.otherform
      .get('order_id')
      ?.setValue(id);
    this.pickUpMsg(id);
  }
  orderStatusUpdate(id: number) {
    this.home.updateOrderStatus({
      "order_id": id,
      "status": 5
    }).subscribe((res: any) => {
    })
  }
}
