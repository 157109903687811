<section class="layout_padding light_bg">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="full">
            <div class="heading_main mt-3">
              <h2><span>ABOUT US</span></h2>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <!-- <p class="blog_head">1. GENERAL CONDITION OF USE.</p> -->
          <p>
           {{about}}
          </p>
        </div>
      </div>
  
     
    </div>
  
    
  </section>
  