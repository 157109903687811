
import { Component } from '@angular/core';
import { HomeService } from '../services/home.service';
import { Subscription, debounceTime, interval, switchMap } from 'rxjs';
import { WebSocketService } from '../services/web-socket.service';
import { NgToastService } from 'ng-angular-popup';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-mobileview',
  templateUrl: './mobileview.component.html',
  styleUrls: ['./mobileview.component.scss']
})
export class MobileviewComponent {
  showDiv = {
    previous: true,
    current: true,
    next: true,
  };
  livePrice: any;
  spotGold: any;
  spotSilver: any;
  spotINR: any;
  primium: any = 0;
  spotGoldUpdate: any;
  spotSilverUpdate: any;
  isGold: boolean = true;
  previousSpotSilverUpdate: any;
  spotSilverColor!: string;
  isLogin: any;
  fAmount!: number;
  buyGolgForm: FormGroup;
  bookSilverForm: FormGroup
  isTotal: boolean = false;
  isAmountLessThan110: boolean = false;
  isAmountLessThan1000: boolean = false;
  goldMaxLimit: any;
  silverMaxLimit: any;
  silverPrimium: any=0;
  treding!: boolean;
  // now!: Date;
  constructor(
    private home: HomeService,
    private webSocketService: WebSocketService,
    private toast: NgToastService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.bookGolgForm = this.fb.group({
      product_name: [''],
      quantity_purchased: ['', [Validators.required]],
      book_rate: [
        '',
        [Validators.required, Validators.pattern('^\\d*\\.?\\d*$'), Validators.maxLength(4), Validators.minLength(4)],
      ],
      order_type: [''],
    });
    this.bookSilverForm = this.fb.group({
      product_name: [''],
      quantity_purchased: ['', [Validators.required]],
      book_rate: [
        '',
        [Validators.required, Validators.pattern('^\\d*\\.?\\d*$'), Validators.maxLength(5), Validators.minLength(5)],
      ],
      order_type: [''],
    });
    this.buyGolgForm = this.fb.group({
      product_name: [''],
      quantity_purchased: ['', [Validators.required]],
      purchased_price: [''],
      order_type: [''],
    });
  }
  now: Date = new Date();

  bookGolgForm!: FormGroup;
  updateTime(): void {
    this.now = new Date();
  }
  userAmount(userInput: any) {
    if (userInput.length == 4) {
      let diffAmount = this.spotGoldUpdate - 110;
      if (diffAmount < userInput) {
        this.isAmountLessThan110 = true;
      } else {
        this.isAmountLessThan110 = false;
        this.toast.warning({
          detail: 'Only less than Rs.110 accepted',
          summary:
            'Please enter an amount that is only Rs.110 less than the live rate.',
          position: 'topCenter',
        });
      }
    }
    // else{
    //   this.bookGolgForm.setValue({book_rate:null})
    // }
  }
  userAmountSilver(userInput: any) {
    if (userInput.length == 5) {
      let diffAmount = this.spotSilverUpdate - 1000;
      if (diffAmount < userInput) {
        this.isAmountLessThan1000 = true;
      } else {
        this.isAmountLessThan1000 = false;
        this.toast.warning({
          detail: 'Only less than Rs.1000 accepted',
          summary:
            'Please enter an amount that is only Rs.1000 less than the live rate.',
          position: 'topCenter',
        });
      }
    }
  }
  checkLogIn() {
    this.toast.warning({
      detail: 'Login Required!',
      summary: 'Please login for book gold',
      position: 'topCenter',
    });
  }
  onBuySubmit(value: string) {
    if (value === 'Gold') {
      this.buyGolgForm.get('product_name')?.setValue('Gold');
      this.buyGolgForm.get('order_type')?.setValue('Gold');
      let selectedQuantity = this.buyGolgForm.get('quantity_purchased')?.value;
      // let fianlAmountGold:any = selectedQuantity * this.spotGoldUpdate
      this.buyGolgForm.get('purchased_price')?.setValue(parseFloat(this.spotGoldUpdate))
    }
    else {
      this.buyGolgForm.get('product_name')?.setValue('Silver');
      this.buyGolgForm.get('order_type')?.setValue('Silver');
      let selectedQuantity = this.buyGolgForm.get('quantity_purchased')?.value;
      // let fianlAmountGold :any= selectedQuantity * this.spotSilverUpdate
      this.buyGolgForm.get('purchased_price')?.setValue(parseFloat(this.spotSilverUpdate))
    }
    this.home.buyGoldAnsSilver(this.buyGolgForm.value).subscribe(
      (res: any) => {
        this.toast.success({
          detail: 'Success!',
          summary: res.msg,
          position: 'topCenter',
        });
        this.buyGolgForm.reset();
        this.goldLimit();
        this.silverLimit();
      },
      (err) => {
        this.toast.error({
          detail: 'Ooops!',
          summary: err.error.msg,
          position: 'topCenter',
        });
      }
    );
  }
  onGoldSubmit(value: string) {
    if (value === 'gold') {
      this.bookGolgForm.get('product_name')?.setValue('Gold');
      this.bookGolgForm.get('order_type')?.setValue('Gold');

      this.home.bookGoldAndSilver(this.bookGolgForm.value).subscribe(
        (res: any) => {
          this.toast.success({
            detail: 'Success!',
            summary: res.msg,
            position: 'topCenter',
          });
          this.bookGolgForm.reset();
          this.goldLimit();
          this.silverLimit();
        },
        (err) => {
          this.toast.error({
            detail: 'Ooops!',
            summary: err.error.msg,
            position: 'topCenter',
          });
        }
      );
    }
    else {
      this.bookSilverForm.get('product_name')?.setValue('Silver');
      this.bookSilverForm.get('order_type')?.setValue('Silver');

      this.home.bookGoldAndSilver(this.bookSilverForm.value).subscribe(
        (res: any) => {
          this.toast.success({
            detail: 'Success!',
            summary: res.msg,
            position: 'topCenter',
          });
          this.bookSilverForm.reset();
        },
        (err) => {
          this.toast.error({
            detail: 'Ooops!',
            summary: err.error.msg,
            position: 'topCenter',
          });
        }
      );
    }
  }
  data: string | undefined;
  public socketSubscription!: Subscription;
  public livePriceSubscription!: Subscription;
  isWeekend(){
    this.now = new Date();
    let isToday=this.now.toLocaleString('en-us', { weekday: 'short' })
    if(isToday=='Sat' || isToday=='Sun') {
      this.treding=false;
    }
    else{
      this.treding=true;
    }
   }
  ngOnInit(): void {
      // Fetch initial rates
      this.home.getInitialRates().subscribe(data => {
        this.spotGoldUpdate = data.carat24.sell_price_per_gram;
         this.spotSilverUpdate = data.silverPrice.sell_price_per_gram;
         this.spotGold=data.carat24.spot_gold
        console.log("sotSilver", this.spotSilverUpdate);
        this.spotGold = data.carat24.spot_gold;
        this.spotSilver=data.silverPrice.spot_silver;
        this.spotINR = data.carat24.spot_inr; //

      });
    this.isWeekend();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    let log: any = localStorage.getItem('user-token-goldBharatFirst');
    this.isLogin = JSON.parse(log);
    this.timeSubscription = interval(1000).subscribe(() => {
      this.updateTime();
    });

    this.spotGoldUpdate = 0;
    this.spotSilverUpdate = 0;

    this.previousSpotGoldUpdate = 0;
    this.previousSpotINRUpdate = 0;

    this.previousSpotSilverUpdate = 0;

    // Initialize spotGoldColor
    this.spotGoldColor = '#e1b737'; // Default color
    this.spotSilverColor = '#e1b737'; // Default color
    this.spotINRColor = '#e1b737';
    // this.primium = 1.50;
    this.socketSubscription = this.webSocketService
      .startFetching()
      .pipe(debounceTime(500))
      .subscribe(
        (message: string) => {
          this.handleWebSocketMessage(message);
        },
        (error: any) => {
        }
      );
  }
  previousSpotGoldUpdate: any;
  previousSpotINRUpdate: any;
  spotGoldColor!: string;
  spotINRColor: any;
  private handleWebSocketMessage(message: string): void {
    this.data = message;

    try {
      const jsonMessage = JSON.parse(message.substring(2));
      const eventData = jsonMessage[1].updatedata;
      const jsonData = JSON.parse(eventData);
      const filteredData = this.filterData(jsonData);

      if (filteredData.length === 3) {
        this.spotGold = filteredData[0].gold1_ask;
        this.spotSilver = filteredData[1].gold1_ask;
        this.spotINR = filteredData[2].gold1_ask;

        this.fetchPremiums();
      } else {
        console.error('Unexpected data format:', filteredData);
      }
    } catch (error) {
      console.error('Error parsing message:', error);
    }
  }

  private filterData(data: any[]): any[] {
    return data.filter(
      (item: any) =>
        item.gold1_symbol === 'SPOT-INR' ||
        item.gold1_symbol === 'SPOT-SILVER' ||
        item.gold1_symbol === 'SPOT-GOLD'
    );
  }

  private fetchPremiums(): void {
    this.http.get('https://liverates-api.goldcentral.in/api/getpremium').subscribe(
      (res: any) => {
        if (res && res.premium !== undefined) {
          this.primium = res.premium;
          this.fetchSilverPremium();
        } else {
          console.error('Premium data is undefined');
        }
      },
      (error: any) => {
        console.error('Error fetching premium:', error);
      }
    );
  }

  private fetchSilverPremium(): void {
    this.http.get('https://liverates-api.goldcentral.in/api/getSilverPremium').subscribe(
      (res: any) => {
        if (res && res.premium !== undefined) {
          this.silverPrimium = res.premium;
          this.calculateUpdatedPrices();
        } else {
          console.error('Silver premium data is undefined');
        }
      },
      (error: any) => {
        console.error('Error fetching silver premium:', error);
      }
    );
  }

  private calculateUpdatedPrices(): void {
    const ozToGrams = 31.102;
    const GOV_GOLD_TAX = 6.25;
    const GOV_SILVER_TAX = 6.75;
    const GST_TAX_PERCENTAGE = 0;

    const totalChargesPercentage = GOV_GOLD_TAX + GST_TAX_PERCENTAGE;
    const totalChargesSilverPercentage = GOV_SILVER_TAX + GST_TAX_PERCENTAGE;

    // Gold price calculation
    const checkGold1 = this.spotGold / ozToGrams;
    const INRrupeeGold = checkGold1 * this.spotINR;
    let convGold: number = (INRrupeeGold / 100) * totalChargesPercentage + INRrupeeGold;
    convGold += (convGold * this.primium) / 100;
    this.spotGoldUpdate = convGold.toFixed(2);

    // Silver price calculation
    const checkSilver = (this.spotSilver / 31.1) * this.spotINR * 1000;
    const inrSilver = checkSilver + (checkSilver / 100) * 6.75;
    const finalSilver = inrSilver + (inrSilver * this.silverPrimium) / 1000;
    this.spotSilverUpdate = (finalSilver + 100).toFixed(2);

    // Update colors based on comparison
    this.spotGoldColor = this.getColor(this.spotGoldUpdate, this.previousSpotGoldUpdate);
    this.spotINRColor = this.getColor(this.spotINR, this.previousSpotINRUpdate);
    this.spotSilverColor = this.getColor(this.spotSilverUpdate, this.previousSpotSilverUpdate);

    // Update previous values
    this.previousSpotGoldUpdate = this.spotGoldUpdate;
    this.previousSpotSilverUpdate = this.spotSilverUpdate;
    this.previousSpotINRUpdate = this.spotINR;
  }


  private getColor(current: number | string, previous: number | string): string {
    const currentNum = typeof current === 'string' ? parseFloat(current) : current;
    const previousNum = typeof previous === 'string' ? parseFloat(previous) : previous;

    if (currentNum > previousNum) {
      return '#FE4A54';
    } else if (currentNum < previousNum) {
      return '#1DC7AC';
    } else {
      return '#e1b737';
    }
  }



  private timeSubscription: Subscription | undefined;

  ngOnDestroy(): void {
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
    if (this.livePriceSubscription) {
      this.livePriceSubscription.unsubscribe();
    }
  }
  callLimit(value: string) {
    if (value === "Gold") {
      this.goldLimit();
    }
    else {
      this.silverLimit();
    }
  }
  goldLimit() {
    this.home.getGoldLimit().subscribe((res: any) => {
      this.goldMaxLimit = res.maxLimitValues;
    })
  }
  silverLimit() {
    this.home.getSilverLimit().subscribe((res: any) => {
      this.silverMaxLimit = res.silverMaxLimitValues;
    })
  }
}