<div class="container">
   
    <h2 class="head">KYC Details</h2>
    
    <div class="row first-sec">
        <div class="back-arrow" (click)="goBack()">
            <img class="backarrow-img" src="../../assets/back_arrow.png" alt="">
          </div>
        <label for="" class="labels">KYC Status</label>
        
                <div class="my-select-gold">
                    <p class="statusChange">{{userKycDetails.msg}}</p>
                </div>
        <div class="col-md-6">
            <div>
                
                <div>
                    <p class="my-select">Business Name : {{userKycDetails.data?.business_name}} </p>
                    <p class="my-select">Business Type : {{userKycDetails.data?.business_type}}</p>
                    <!-- <p class="my-select">Available Margin : {{userKycDetails.data?.}} </p> -->
                    <p class="my-select">Proprietor Name : {{userKycDetails.data?.proprietor_name}} </p>
                    <p class="my-select">Phone Number : {{userKycDetails.data?.register_phone_number}} </p>
                    <p class="my-select">Email : {{userKycDetails.data?.email}} </p>
                </div>


            </div>
        </div>
        <div class="col-md-6">
            <div>
               
                <div>
                    <p class="my-select">City : {{userKycDetails.data?.city}} </p>
                    <p class="my-select">State : {{userKycDetails.data?.state}}</p>
                    <!-- <p class="my-select">Available Margin : {{userKycDetails.data?.}} </p> -->
                    <p class="my-select">Pin Code : {{userKycDetails.data?.pin_code}} </p>
                    <p class="my-select">Bank Name : {{userKycDetails.data?.bank_name}} </p>
                    <p class="my-select">Account Number : {{userKycDetails.data?.account_number}} </p>
                </div>


            </div>
        </div>
    </div>
</div>
