<section class="first-sec">
    <h2 class="head">Booking</h2>
    <div>
        <div class="box">
            <div class="d-flex">
                <div class="gold-live-main">
                    <p class="labelhead"><i class="fa-solid fa-phone my-phone"></i> &nbsp;&nbsp;Booking Number</p>
                    <p class="gold-live">+91 91600 06702</p>
                </div>
            </div>
        </div>
        <div class="box" >
            <div class="d-flex">
                <div class="gold-live-main">
                    <p class="labelhead"> <i class="fa-regular fa-envelope"></i>&nbsp;&nbsp;Account Create</p>
                    <p class="gold-live">info@goldsikka.com</p>
                </div>
            </div>
        </div>
        <div class="box" >
            <div class="d-flex">
                <div class="gold-live-main">
                    <p class="labelhead"><i class="fa-solid fa-headset"></i>&nbsp;&nbsp;Technical Support</p>
                    <p class="gold-live">info@goldsikka.com</p>
                </div>
            </div>
        </div>
        <div class="box-addres" >
            <div class="d-flex">
                <div class="gold-live-main">
                    <p class="labelhead-add"><i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp; Address</p>
                    <p class="gold-live">Goldsikka Limited 2nd Floor, H.No.1-10-60 to 62, Ashoka Raghupathi Chambers , S.P. Road, Begumpet Hyderabad, Telangana-500016, India.</p>
                </div>
            </div>
        </div>
        <div>

        </div>

    </div>
</section>