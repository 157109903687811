<div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="card-title m-b-0">Notifications</h5>
          </div>
         
          <!-- <div *ngIf="noData" class="noData">
            <img src="../../assets/Animation - 1712133187330.gif" alt="">
            <h3 class="text-danger text-center">No Data Found</h3>
          </div> *ngIf="!noData" -->
          <div class="table-responsive" >
            <div class="table-container">
              <table class="table text-center">
                <thead class="thead-light">
                  <tr class="head_align">
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date & Time</th>
                                  
                  </tr>
                </thead>
                <tbody class="customtable">
                  <tr *ngFor="let not of allnoti">
                    <td>{{ not?.status }}</td>
                    <td>{{ not?.message }}</td>
                    <td>{{not?.created_at |date:'medium'}}</td>
                    <!-- <td [ngClass]="pro.product_name == 'Gold' ? 'myGoldColor' : 'mySilverColor'">{{ pro.product_name }}
                    </td> -->
                  </tr>
                </tbody>

               

               

              </table>
  
            </div>
          </div>

          <div class="table-responsive" >
            <div class="table-container">
              <table class="table1">
                <!-- <thead class="thead-light">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                                  
                  </tr>
                </thead> -->
             
               
                <tbody class="customtable1" *ngFor="let not of allnoti">
                  <tr>
                    <td>Type</td>
                    <td>:</td>
                    <td>{{ not?.status }}</td>
                  
                    <!-- <td [ngClass]="pro.product_name == 'Gold' ? 'myGoldColor' : 'mySilverColor'">{{ pro.product_name }}
                    </td> -->
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>:</td>
                    <td>{{ not?.message }}</td>

                  </tr>
                  <tr>
                    <td>Date & Time</td>
                    <td>:</td>
                    <td>{{not?.created_at |date:'medium'}}</td>

                  </tr>
                </tbody>

               

              </table>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>