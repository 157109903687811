


<section class="first-sec">
    <h2 class="head">Margin</h2>
    <div>
        <label for="" class="labels">Select Type</label>
        <select class="my-select-gold" (change)="goldSelect($event)">
            <option value="" disabled>Select</option>
            <option value="Gold">Gold Margin</option>
            <option value="Silver">Silver Margin</option>
        </select>         
        <div *ngIf="isGold">
            <p class="my-select">Total Margin : {{margin.total}} </p>
        <p class="my-select">Used Margin : {{margin.distributed}}</p>
        <p class="my-select">Available Margin : {{margin.available}} </p>
        <p class="my-select">Fixed Margin : {{margin.fixed}} </p>
        <p class="my-select">Credit  Margin : {{margin.credit}} </p>
        <p class="my-select">Free Margin : {{margin.free}} </p>
        </div>
        <div *ngIf="!isGold">
            <p class="my-select">Total Margin : {{margin.silver_total}} </p>
            <p class="my-select">Used Margin : {{margin.silver_distributed}}</p>
            <p class="my-select">Available Margin : {{margin.silver_available}} </p>
            <p class="my-select">Fixed Margin : {{margin.silver_fixed}} </p>
            <p class="my-select">Credit  Margin : {{margin.silver_credit}} </p>
            <p class="my-select">Free Margin : {{margin.silver_free}} </p>
        </div>

    </div>
</section>