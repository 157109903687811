import { Component } from '@angular/core';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  allnoti: any;
  constructor(private home:HomeService){}
ngOnInit():void{
  this.readNoti(1);
  this.getNotification();
}
getNotification(){
this.home.getNotification().subscribe((res:any)=>{
  this.allnoti=res.readAndUnreadNotifications;
  console.log('not',res); 
})
}
readNoti(id:any){
  this.home.notificationRead().subscribe((res:any)=>{
    console.log('read',res)
  })
}
}
