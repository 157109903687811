<section class="loacte-section">
  <p class="locate-heading">Locate Us</p>
  <div class="container">
    <div class="row">
      <div class="col-md-6 firstcolumn-ones">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.320017069927!2d78.46306547421092!3d17.44439070116234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90a36223132b%3A0x56f6b02c055805b6!2sGoldsikka%20Limited!5e0!3m2!1sen!2sin!4v1709896814533!5m2!1sen!2sin"
          height="100%"
          class="mymap-image"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div class="col-md-6 firstcolumn-ones">
        <div class="icon-box-div">
          <div class="custom_wraps">
            <div class="mywrap">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
            <div class="toches-sec">
              <p class="get-touchs">Get In Touch</p>
              <p class="what-app-para">
                Whatsapp No: <a href="#">+91 91600 06702</a>
              </p>
            </div>
          </div>

          <div class="custom_wraps mt-4">
            <div class="mywrap">
              <i class="fa-solid fa-envelope"></i>              <!-- <i class="fa fa-envelope-o" aria-hidden="true"></i> -->
            </div>
            <div class="toches-sec">
              <p class="get-touchs">Email Us</p>
              <p class="what-app-para">
                <a href="#">info@goldsikka.com </a>
              </p>
            </div>
          </div>

          <div class="custom_wraps mt-4">
            <div class="mywrap-next">
              <i class="fa fa-home" aria-hidden="true"></i>
            </div>
            <div class="toches-sec">
              <p class="get-touchs-address">Address</p>
              <p class="address-paragraph">
                Goldsikka Limited 2nd Floor, H.No.1-10-60 to 62, Ashoka
                Raghupathi Chambers , S.P. Road, Begumpet Hyderabad,
                Telangana-500016, India
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
