<section class="our-products-section">
    <p class="products-paragraph">Our Products</p>
    <div class="text-center">
      <img src="../../assets/bhavav.png" class="bhavanimg" alt="" />
    </div>
    <div class="container images-section">
      <div class="row">
        <div class="col-md-3">
          <img src="../../assets/goldbars.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph">Gold Bars</p>
        </div>
  
        <div class="col-md-3">
          <img src="../../assets/silverbars.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph">Silver Bars</p>
        </div>
  
        <div class="col-md-3">
          <img src="../../assets/goldcoins.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph">Gold Coins</p>
        </div>
  
        <div class="col-md-3">
          <img src="../../assets/silvercoins.png" class="goldbarsimg" alt="" />
          <p class="goldbars-paragraph">Silver Coins</p>
        </div>
      </div>
    </div>
  </section>