import { Component } from '@angular/core';
import { HomeService } from '../services/home.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent {
about: any;
constructor(private home:HomeService,private router: Router){}
ngOnInit():void{
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    }
  });
this.getAboutus();
}
getAboutus(){
  this.home.aboutUs().subscribe((res:any)=>{
    this.about=res.content;
  })
}
}
