import { Component } from '@angular/core';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-margin',
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.scss']
})
export class MarginComponent {
margin: any;
  isGold: boolean=true;
constructor(private home: HomeService){

}
ngOnInit():void{
this.marginGet()
}
marginGet(){
  this.home.getMargin().subscribe((res:any)=>{
    this.margin=res;
    this.isGold=true;
  })
}
marginSilverGet(){
  this.home.silverGetMargin().subscribe((res:any)=>{
    this.margin=res;
    this.isGold=false;
    
  })
}
goldSelect(event:any){
  if(event.target.value === 'Gold'){
    this.marginGet()
  }
  else{
    this.marginSilverGet()
  }
}
}
